import { Breadcrumb, Button, Card, Col, Drawer, Form, Icon, Layout, Menu, Row, Select, Switch, Tag, Tooltip, Typography, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import _, { get } from 'lodash';
import { canAccess } from 'o2o_layout';
import { TypeTermOfOrg, UISetting } from 'o2o_layout/lib/common/model';
import { Methods } from 'o2o_layout/lib/common/rest';
import * as React from 'react';
import { Link } from 'react-router-dom';
import CommonTypeProps from '../../../common/CommonTypeProps';
import { BIGDATA_CONSTANT } from '../../../common/constant';
import { getCurrentURL, getPrimaryColor } from '../../../common/helper';
import { DashboardPageLayout } from '../../../layout/dashboard/DashboardPageLayout';
import { TypeEDMSendInBlue } from '../../../redux/reducer/edmSendInBlueReducer';
import { typeCurrentUserOrgs } from '../../../redux/reducer/selectCurrentOrgReducer';
import { CommonNotificationSaveError, CommonNotificationUpdateSuccessfully } from '../../../shares/CommonNotification';
import { MetaHeader } from '../../../shares/MetaHeader';
import { O2OFormItem } from '../../../shares/O2OFormItem';
import { CommonDAL } from '../../../shares/dal/CommonDAL';
import { typeUpdateOrg } from '../../orgs/OrgsAction';
import { typeUpdateAcount } from '../ConsoleUsersAction';
import { FormCompany, IndustriesList } from '../profile/FormMyCompany';
import { DrawerTerm } from './DrawerTerm';
import { FormPasswordPolicy } from './PassPolicy';
import { SessionPolicy } from './SessionPolicy';
import { UpdateStatusTerm } from './UpdateStatusTerm';
import CustomUI from './components/custom-ui';
import DataMaking from './components/data-making';
import Localization from './components/localization';
import { OrgAvatar } from './OrgAvatar';


interface Props extends CommonTypeProps, FormComponentProps {
    updateAccount: typeUpdateAcount,
    updateOrgByAdminClient: typeUpdateOrg,
    changePwd: any,
    edmSendInBlue: TypeEDMSendInBlue,
    updateEDMSendInBlue: any,
    getEDMSendInBlue: any,
    getCurrentUserOrgs: any
    currentUserOrgs: typeCurrentUserOrgs
    getMe: any
    uiSetting: UISetting

}

export interface IGeneralSetting {
    allowedDomains: string[],
    invitationUsersToJoin: boolean
}

export class UserCompany extends React.Component<Props, any> {
    formRef: any
    formRefInfo: any
    formRefEDM: any
    formRefChangePassword: any

    state = {
        confirmDirty: false,
        visibleCompany: false,
        visibleTerm: false,
        selectedMenu: "info",
        loadingSave: false,
        loadingSaveClose: false,
        generalSetting: {} as IGeneralSetting,
        loading: false as boolean
    };

    componentDidMount = () => {
        this.props.updateMetaTitle({ title: BIGDATA_CONSTANT.trackTitle.Organization })
        this.timer = setTimeout(() => {
            this.props.updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.Organization,
                title: BIGDATA_CONSTANT.trackTitle.Organization,
                app: "ACCOUNT",
                url: getCurrentURL(this.props.location)
            })
            clearTimeout(this.timer)
        }, 5000)
        this.loadGeneralSetting()
    }
    timer: any

    componentWillUnmount() {
        clearTimeout(this.timer)
    }


    checkCurrentUserIsSuperAdmin = (): boolean => {
        if (this.props.user) {
            if (this.props.user.orgCtx) {
                let roles = this.props.user.orgCtx.roles;
                let isSP = _.find(roles, (e) => {
                    return e == "SUPER_ADMIN"
                });

                if (isSP) {
                    return true
                }
                return false
            }
            return false
        }
        return false;
    }

    renderBreadCrumb = () => {
        return (
            <Row type="flex" justify="space-between" align="middle" style={{ marginTop: 20 }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Admin</Breadcrumb.Item>
                    <Breadcrumb.Item>Organization</Breadcrumb.Item>
                </Breadcrumb>

            </Row>

        )
    }

    submitUpdateOrg = (e: any, type: string) => {

        e.preventDefault();
        const form = this.formRef.props.form;

        form.validateFields((err: any, values: any) => {
            if (err) {
                return;
            }

            type === "save" ? this.setState({ loadingSave: true }) : this.setState({ loadingSaveClose: true })
            // let isSAdmin = this.checkCurrentUserIsSuperAdmin()
            values.id = this.props.user.orgCtx ? this.props.user.orgCtx.orgId : null
            this.props.updateOrgByAdminClient(values, false)
                .then(() => {
                    if (this.props.commonResponse.success) {
                        type === "save" ? this.setState({ loadingSave: false }) : this.setState({ loadingSaveClose: false })
                        notification.success({
                            message: BIGDATA_CONSTANT.message.default.title.success,
                            description: BIGDATA_CONSTANT.message.notification.success.update,
                            icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                        })

                        type === "save-close" && this.setState({
                            visibleCompany: false
                        })
                        type === "save" ? this.setState({ loadingSave: false }) : this.setState({ loadingSaveClose: false })
                        setTimeout(() => {
                            // window.location.reload()
                            this.props.getMe()
                        }, 1000)
                    } else {
                        type === "save" ? this.setState({ loadingSave: false }) : this.setState({ loadingSaveClose: false })
                        type === "save" && this.setState({ loadingSave: false })
                        notification.error({
                            message: BIGDATA_CONSTANT.message.default.title.error,
                            description: this.props.commonResponse.message,
                        })
                    }


                })
                .catch((e: any) => {
                    type === "save" ? this.setState({ loadingSave: false }) : this.setState({ loadingSaveClose: false })
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: e.data.message,
                    })
                })
        })


    }

    submitPwd = (e: any) => {
        e.preventDefault();
        this.formRefChangePassword.props.form.validateFieldsAndScroll((err: any, values: any) => {
            if (!err) {
                this.props.setGlobalLoading(true)
                this.props.changePwd(values.current_password, values.password, values.confirm)
                    .then(() => {
                        this.props.setGlobalLoading(false)
                        this.formRefChangePassword.props.form.resetFields()
                        notification.success({
                            message: BIGDATA_CONSTANT.message.default.title.success,
                            description: "Change password successfuly",
                            icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                        })
                    })
            }

        });
    }



    submitUpdateInfo = (e: any) => {
        e.preventDefault();
        this.formRefInfo.props.form.validateFieldsAndScroll((err: any, values: any) => {
            if (!err) {
                let user: any = Object.assign(values, {
                    email: values.id,
                    status: 'active'
                })

                let isSPA = this.checkCurrentUserIsSuperAdmin()
                let promise: any = this.props.updateAccount(user, isSPA)
                this.props.setGlobalLoading(true)
                promise
                    .then(() => {

                        if (this.props.commonResponse.success) {



                            notification.success({
                                message: BIGDATA_CONSTANT.message.default.title.success,
                                description: `User ${this.props.user.id} updated successfully`,
                                icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                            })

                            this.props.setGlobalLoading(false)


                        } else {
                            notification.error({
                                message: BIGDATA_CONSTANT.message.default.title.error,
                                description: _.get(this.props.commonResponse, "message", "Something went wrong."),
                            })
                        }

                    })
                    .catch(() => {

                        this.props.setGlobalLoading(false)
                    })

            }

        });
    }


    getTermOfOrg = () => {
        let { id } = this.props.user.orgCtx ? this.props.user.orgCtx.organization : { id: "" }
        let term: TypeTermOfOrg | undefined = this.props.currentUserOrgs.orgs.terms[id] || undefined
        return term
    }

    renderMoreSetting = () => {
        return <Row type='flex' gutter={[5, 5]}>
            <Col xs={24}>
                <Typography.Title level={4}>
                    General Setting
                </Typography.Title>
            </Col>
            <Col sm={24} lg={12}>
                <Card size='small'>
                    <O2OFormItem label={
                        <span>
                            Allowed Account Domains <Tooltip title={
                                <ul style={{ paddingLeft: 20, margin: 0 }}>
                                    <li>List of account domains are able to to the organization</li>
                                    <li>Turn off the restriction by setting empty list</li>
                                </ul>
                            }>
                                <Icon type='question-circle' />
                            </Tooltip>
                        </span>
                    }>
                        {this.props.form.getFieldDecorator("domains", {
                            initialValue: this.state.generalSetting.allowedDomains
                        })(<Select style={{ width: '100%' }} showSearch showArrow placeholder="your-domain.com" mode={"tags"} />)}
                    </O2OFormItem>

                    <O2OFormItem label={<span>
                        Invite Users to Join Organization <Tooltip title={'Turn on User Invitation to Join Organization'}>
                            <Icon type='question-circle' />
                        </Tooltip>
                    </span>}>
                        {this.props.form.getFieldDecorator("invitationUsersToJoin", {
                            valuePropName: 'checked',
                            initialValue: this.state.generalSetting.invitationUsersToJoin,
                        })(<Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />)}
                    </O2OFormItem>

                    <Button onClick={() => {
                        this.props.form.validateFields(async (err: any, values: any) => {
                            if (!err) {
                                this.setState({
                                    loading: true
                                })
                                const result = this.props.commonActionFromDALV2(CommonDAL.buildURL,
                                    BIGDATA_CONSTANT.url.api + `mngmnt-client/orgs/config/general-settings/save`,
                                    {
                                        allowedDomains: values.domains,
                                        invitationUsersToJoin: values.invitationUsersToJoin
                                    },
                                    Methods.POST
                                )
                                try {
                                    const response = await result
                                    if (response.success) {
                                        setTimeout(() => {
                                            this.loadGeneralSetting()
                                        }, 1000);
                                        CommonNotificationUpdateSuccessfully()
                                    } else {
                                        CommonNotificationSaveError(get(response, 'message'))
                                    }
                                    this.setState({
                                        loading: false
                                    })
                                } catch (error) {
                                    this.setState({
                                        loading: false
                                    })
                                    CommonNotificationSaveError(get(error, 'message'))
                                }
                            }
                        })
                    }} style={{ marginTop: 8 }} loading={this.state.loading} type='primary'>Save</Button>
                </Card>
            </Col>
        </Row>
    }

    loadGeneralSetting = async () => {
        const result = this.props.commonActionFromDALV2(CommonDAL.buildURL,
            BIGDATA_CONSTANT.url.api + `mngmnt-client/orgs/config/general-settings/get`,
            {},
            Methods.GET
        )
        try {
            const response: any = await result;
            if (response.success) {
                this.setState({
                    generalSetting: get(response, 'result', {})
                })
            } else {
                this.setState({
                    generalSetting: {}
                })
            }
        } catch (error) {
            this.setState({
                generalSetting: {}
            })
        }
    }


    renderContent = () => {
        if (this.state.selectedMenu === 'general-setting') {
            return this.renderMoreSetting()
        }
        else if (this.state.selectedMenu == "info") {
            return this.renderInfoContent()
        } else if (this.state.selectedMenu == "term") {
            return this.renderTermContent()
        } else if (this.state.selectedMenu == "password") {
            return this.renderPolicyContent()
        } else if (this.state.selectedMenu == "session") {
            return this.renderSessionPolicy()
        } else if (this.state.selectedMenu === "custom-ui") {
            return this.renderCustomUI()
        } else if (this.state.selectedMenu === "data-masking") {
            return this.renderDataMaking()
        } else if (this.state.selectedMenu === "localization") {
            return this.renderLocalization()
        }

        return null
    }

    renderSessionPolicy = () => {
        const userRoles = this.props.user ? (this.props.user.orgCtx ? this.props.user.orgCtx.roles : []) : [];
        return (
            <Row gutter={[20, 20]}>

                <Col xs={24}>

                    {
                        canAccess(["ADMIN", "SUPER_ADMIN"], userRoles) ? (
                            <React.Fragment>
                                <Col xs={24}>

                                    <SessionPolicy
                                        visible={this.state.selectedMenu == "session" ? true : false}
                                        commonActionFromDAL={this.props.commonActionFromDAL}
                                        onClose={() => {

                                        }}
                                        success={() => {

                                        }}

                                    />

                                </Col>
                            </React.Fragment>
                        ) : (
                            null
                        )
                    }


                </Col>
            </Row>
        )
    }

    renderPolicyContent = () => {
        const userRoles = this.props.user ? (this.props.user.orgCtx ? this.props.user.orgCtx.roles : []) : [];
        return (
            <Row gutter={[20, 20]}>

                <Col xs={24}>

                    {
                        canAccess(["ADMIN", "SUPER_ADMIN"], userRoles) ? (
                            <React.Fragment>
                                <Col xs={24}>

                                    <FormPasswordPolicy
                                        visible={this.state.selectedMenu == "password" ? true : false}
                                        commonActionFromDAL={this.props.commonActionFromDAL}
                                        onClose={() => {

                                        }}
                                        success={() => {

                                        }}

                                    />

                                </Col>
                            </React.Fragment>
                        ) : (
                            null
                        )
                    }


                </Col>
            </Row>
        )
    }

    renderCustomUI = () => {
        return (
            <CustomUI commonActionFromDALV2={this.props.commonActionFromDALV2} />
        )
    }

    renderDataMaking = () => {
        return (
            <DataMaking commonActionFromDALV2={this.props.commonActionFromDALV2} />
        )
    }

    renderLocalization = () => {
        return (
            <Localization commonActionFromDALV2={this.props.commonActionFromDALV2} />
        )
    }

    renderType = (key: string) => {
        switch (key) {

            case "Trial":
                return <Tag style={{ background: "#EB2C56", color: "#fff", borderColor: "#EB2C56" }}>{key}</Tag>
            case "Basic":
                return <Tag style={{ background: "#FCBC04", color: "#fff", borderColor: "#FCBC04" }}>{key}</Tag>
            case "Profesional":
                return <Tag style={{ background: "#38A456", color: "#fff", borderColor: "#38A456" }}>{key}</Tag>
            case "Enterprise":
                return <Tag style={{ background: "#9837FF", color: "#fff", borderColor: "#9837FF" }}>{key}</Tag>
            case "Contract":
                return <Tag style={{ background: "#47BBBD", color: "#fff", borderColor: "#47BBBD" }}>{key}</Tag>
            default:
                return <Tag>{key}</Tag>
        }
    }

    renderInfoContent = () => {
        return (
            <Row gutter={[20, 20]}>


                <Col xs={24}>
                    <Row type="flex" gutter={[20, 20]} align="middle" justify='start' >
                        <Col xs={8}>
                            <Row type="flex" gutter={[20, 20]} align="middle">
                                <Col xs={24}>
                                    <Typography.Title level={4}>{this.props.user.orgCtx ? this.props.user.orgCtx.organization.name : "--/--"}
                                        <Button icon="edit" type="link" onClick={() => {
                                            this.setState({
                                                visibleCompany: true
                                            })
                                        }}

                                        /></Typography.Title>

                                </Col>


                                <Col xs={24}><Icon type="mail" /> {this.props.user.orgCtx ? this.props.user.orgCtx.organization.email || "--/--" : "--/--"}</Col>

                                <Col xs={24}><Icon type="phone" /> {this.props.user.orgCtx ? this.props.user.orgCtx.organization.phone || "--/--" : "--/--"} </Col>

                                <Col xs={24}><Icon type="home" /> {this.props.user.orgCtx ? this.props.user.orgCtx.organization.address || "--/--" : "--/--"}  </Col>

                                <Col xs={24}><Icon type="global" /> {this.props.user.orgCtx ? this.props.user.orgCtx.organization.website || "--/--" : "--/--"} </Col>


                                <Col xs={24}>
                                    {this.renderType(this.props.user.orgCtx ? _.get(IndustriesList, this.props.user.orgCtx.organization.accountType, "Other") : "--/--")}
                                    <Tag> {this.props.user.orgCtx ? _.get(IndustriesList, this.props.user.orgCtx.organization.industry, "Other") : "--/--"} </Tag>
                                </Col>

                            </Row>
                        </Col>
                        <Col>
                            <OrgAvatar
                                commonDALV2={this.props.commonActionFromDALV2}
                                onSuccess={() => {
                                    this.props.getMe()
                                }}
                                org={this.props.user.orgCtx ? this.props.user.orgCtx.organization : undefined}
                            />
                        </Col>




                    </Row>
                </Col>
            </Row>
        )
    }

    renderTermContent = () => {
        let term: TypeTermOfOrg | undefined = this.getTermOfOrg();
        const userRoles = this.props.user ? (this.props.user.orgCtx ? this.props.user.orgCtx.roles : []) : [];
        return (
            <Row gutter={[20, 20]}>
                <Button icon="edit" type="link" onClick={() => {
                    this.setState({
                        visibleCompany: true
                    })
                }}
                    style={{
                        position: "absolute",
                        right: 20,
                        zIndex: 22
                    }}
                />

                <Col xs={24}>
                    <Row type="flex" gutter={[20, 20]} align="middle">

                        {
                            canAccess(["ADMIN", "SUPER_ADMIN"], userRoles) ? (
                                <React.Fragment>
                                    <Col xs={24}> <UpdateStatusTerm term={term ? term.term : undefined} success={() => {
                                        this.props.getCurrentUserOrgs()
                                    }} commonActionFromDAL={this.props.commonActionFromDAL} status={term ? term.active : false} />  </Col>
                                    <Col xs={24}>
                                        {this.renderTerm()}
                                    </Col>
                                </React.Fragment>
                            ) : (
                                null
                            )
                        }


                    </Row>
                </Col>
            </Row>
        )
    }

    renderTheme() {

        let { name } = this.props.user.orgCtx ? this.props.user.orgCtx.organization : { name: "" }
        let initials: any = name.match(/\b\w/g) || [];
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

        return (
            <DashboardPageLayout  {...this.props} selectedLeftNav="organization" selectedSubMenu="console" >
                <div className="org-page">

                    <MetaHeader title={this.props.ui.meta.title} />

                    <div className="contentLayout">
                        {this.renderBreadCrumb()}
                        <br />
                        <div className="containerInner">

                            <Layout>
                                <Layout.Sider width={200} theme="light" style={{ marginRight: 30 }}>
                                    <Menu
                                        mode="inline"
                                        selectedKeys={[this.state.selectedMenu]}
                                        onClick={(e: any) => {
                                            this.setState({
                                                selectedMenu: e.key
                                            })
                                        }}
                                        style={{ height: '100%' }}
                                    >
                                        <Menu.Item key={"info"}>
                                            Information
                                        </Menu.Item>
                                        <Menu.SubMenu title={"Security"}>
                                            <Menu.Item key={"password"}>Password Policy</Menu.Item>
                                            <Menu.Item key={"session"}>Session Policy</Menu.Item>
                                        </Menu.SubMenu>
                                        <Menu.SubMenu title="More Settings">
                                            <Menu.Item key="custom-ui" style={{ borderBottom: "1px soild #000" }}>
                                                Custom UI
                                            </Menu.Item>
                                            <Menu.Item key="data-masking">
                                                Data masking
                                            </Menu.Item>
                                            <Menu.Item key="localization">
                                                Localization
                                            </Menu.Item>
                                            <Menu.Item key="term">
                                                Terms of Use
                                            </Menu.Item>
                                            <Menu.Item key="general-setting">
                                                General Setting
                                            </Menu.Item>
                                        </Menu.SubMenu>
                                    </Menu>
                                </Layout.Sider>
                                <Layout.Content style={{ overflow: 'hidden' }}>

                                    {this.renderContent()}

                                </Layout.Content>
                            </Layout>


                        </div>
                    </div>

                </div>
            </DashboardPageLayout>
        )
    }

    renderDrawer = () => {


        return (
            <Drawer
                title="UPDATE INFORMATION"
                visible={this.state.visibleCompany}
                onClose={() => this.setState({
                    visibleCompany: false
                }, () => {
                    this.formRef.props.form.resetFields()
                })}
                width={"600px"}
                bodyStyle={{ marginBottom: 80 }}
            >

                <FormCompany locale={this.props.locale}
                    orgCtx={this.props.user.orgCtx}
                    loading={this.props.ui.isLoading}
                    wrappedComponentRef={(propsRef: any) => this.formRef = propsRef}
                    submit={this.submitUpdateOrg}
                    visibleCompany={this.state.visibleCompany}
                />
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e8e8e8',
                        padding: '10px 16px',
                        textAlign: 'right',
                        left: 0,
                        background: '#fff',
                        borderRadius: '0 0 4px 4px',
                    }}
                >
                    <Button
                        style={{ marginRight: 5, }}
                        onClick={() => this.setState({
                            visibleCompany: false
                        }, () => {
                            this.formRef.props.form.resetFields()
                        })}
                    >
                        Discard
                    </Button>
                    <Button loading={this.state.loadingSave} style={{ marginRight: 5 }} onClick={(e) => {
                        this.submitUpdateOrg(e, "save")
                    }} type="primary">
                        Save
                    </Button>
                    <Button loading={this.state.loadingSaveClose} onClick={(e) => {
                        this.submitUpdateOrg(e, "save-close")
                    }} type="primary">
                        Save & Close
                    </Button>
                </div>

            </Drawer>
        )
    }
    renderTerm = () => {
        return (
            <DrawerTerm
                onClose={() => {

                }}
                commonActionFromDAL={this.props.commonActionFromDAL}
                term={this.getTermOfOrg()}
                visible={this.state.visibleTerm}
                success={() => {
                    this.props.getCurrentUserOrgs()
                }}
                user={this.props.user}

            />
        )
    }

    render() {
        return (
            <React.Fragment>
                {this.renderDrawer()}
                {this.renderTheme()}
            </React.Fragment>

        );
    }
}

export const UserCompanyComponent = Form.create<Props>({ name: 'profile_form' })(UserCompany)