import _ from "lodash";
import { toQuery } from "../../../../common/helper";
import { TypeCriteriaInternalPermission } from "../models/model";
import { BIGDATA_CONSTANT } from "../../../../common/constant";
import { Methods, RestClient } from "o2o_layout/lib/common/rest";

class DAL {
    search(dto: TypeCriteriaInternalPermission) {
        const q = toQuery(_.omitBy({
            page: dto.page,
            pageSize: dto.pageSize,
        }, _.isNil),)
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/internal-permissions/search?${q}`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto).then(
                (response: any) => {
                    const user: any = response.data;
                    resolve(user);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    allPermission() {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/internal-permissions/permissions/_all`;
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then(
                (response: any) => {
                    const user: any = response.data;
                    resolve(user);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }
    grantPermission(dto: any) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/internal-permissions/grant`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto ? dto : {}).then(
                (response: any) => {
                    const user: any = response.data;
                    resolve(user);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }
    revokePermissions(dto: any) {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/internal-permissions/revoke`;
        var restClient = new RestClient(Methods.POST, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, dto ? dto : {}).then(
                (response: any) => {
                    const user: any = response.data;
                    resolve(user);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }
    hasOwnerPermission() {
        let url = BIGDATA_CONSTANT.url.api + `mngmnt/internal-permissions/has_owner_permission`;
        var restClient = new RestClient(Methods.GET, url);
        return new Promise((resolve, reject) => {
            restClient.execute({}, {}).then(
                (response: any) => {
                    const user: any = response.data;
                    resolve(user);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }
}

export const InternalPermissionDAL = new DAL()