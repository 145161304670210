import * as React from 'react';
import { Breadcrumb, Row, Col, Layout, Menu, Table, Descriptions, Popconfirm, Button, Typography, notification, Icon } from 'antd';
import { Link } from 'react-router-dom';
import CommonTypeProps from '../../../common/CommonTypeProps';
import { DashboardPageLayout } from '../../../layout/dashboard/DashboardPageLayout';
import _ from 'lodash';
import { LinkO2O } from '../../../common/LinkO2O';
import { UserTokens, TypeUserToken } from './model/usertoken';
import { TypeCriteriaSearchUserToken } from './dal/UserTokenDAL';
import { PaginationProps } from 'antd/lib/pagination';
import { ColumnProps } from 'antd/lib/table';
import { FormattedDate } from 'react-intl';
import { BIGDATA_CONSTANT } from '../../../common/constant';
import { getCurrentURL, getPrimaryColor } from '../../../common/helper';
import { MetaHeader } from '../../../shares/MetaHeader';


interface Props extends CommonTypeProps {
    getUserTokens: any,
    userTokens: UserTokens,
    logoutById: any
}
export class UserTokenComponent extends React.Component<Props, any> {

    state = {
        criteria: {} as TypeCriteriaSearchUserToken,
        pagination: {} as PaginationProps,
        columns: [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                ellipsis: false
            },
            {
                title: 'Expired Time',
                dataIndex: 'expiredAt',
                key: 'expiredAt',
                ellipsis: true,

                render: (text: any, record: TypeUserToken, index: number) => {
                    return (
                        record.expiredAt ? <FormattedDate value={new Date(record.expiredAt)}
                            year='numeric'
                            month='2-digit'
                            day='2-digit'
                            hour="2-digit"
                            minute="2-digit"
                            second="2-digit"
                        /> : ""
                    )
                },

            }, {

                title: 'Last Accessed',
                dataIndex: 'lastAccessedAt',
                key: 'lastAccessedAt',
                ellipsis: true,
                render: (text: any, record: TypeUserToken, index: number) => {
                    return (
                        record.lastAccessedAt ? <FormattedDate value={new Date(record.lastAccessedAt)}
                            year='numeric'
                            month='2-digit'
                            day='2-digit'
                            hour="2-digit"
                            minute="2-digit"
                            second="2-digit"
                        /> : ""
                    )
                },

            }, {
                title: 'Logout',
                dataIndex: 'lastAccessedAt',
                key: 'lastAccessedAt',
                width: 80,
                fixed: "right",
                render: (text: any, record: TypeUserToken, index: number) => {
                    return (
                        <Popconfirm title="Are you sure logout this session?" onConfirm={() => {
                            this.logOutId(record)
                        }}>
                            <Button size="small" type="link" icon="logout"></Button>
                        </Popconfirm>
                    )
                },
            }

        ] as ColumnProps<{}>[]
    };

    logOutId = (record: TypeUserToken) => {
        this.props.setGlobalLoading(true)
        this.props.logoutById(record.id)
            .then((e: any) => {
                if (e.success) {

                    notification.success({
                        message: BIGDATA_CONSTANT.message.default.title.success,
                        description: "Logout successfully",
                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                    })
                } else {

                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: "Logout fail"
                    })
                }
                this.props.setGlobalLoading(false)
            })
    }

    componentDidMount = () => {
        this.load()
        this.props.updateMetaTitle({ title: BIGDATA_CONSTANT.trackTitle.MyToken })
        this.timer = setTimeout(() => {
            this.props.updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.MyToken,
                title: BIGDATA_CONSTANT.trackTitle.MyToken,
                app: "ACCOUNT",
                url: getCurrentURL(this.props.location)
            })
            clearTimeout(this.timer)
        }, 5000)
    }
    timer: any

    componentWillUnmount() {
        clearTimeout(this.timer)
    }


    load = () => {
        this.props.setGlobalLoading(true);

        this.props.getUserTokens(this.state.criteria)
            .then(() => {
                this.props.setGlobalLoading(false)
                console.log(this.props.userTokens)
            })
    }


    renderBreadCrumb = () => {
        return (
            <Row type="flex" justify="space-between" align="middle" style={{ marginTop: 20 }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <LinkO2O {...this.props} to={{
                            pathname: "/security/2fa-security"
                        }}>Security</LinkO2O>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>Tokens</Breadcrumb.Item>
                </Breadcrumb>

            </Row>

        )
    }
    handleTableChange = (pagination: any, filters: any, sorter: any) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;


        this.setState({
            pagination: pager,
            criteria: Object.assign(this.state.criteria, { page: pager.current })
        });


        this.load();
    };

    renderTheme() {


        return (
            <DashboardPageLayout  {...this.props} selectedLeftNav="my-security" selectedSubMenu="my-account" >
                <div className="org-page">

                    <MetaHeader title={this.props.ui.meta.title} />

                    <div className="contentLayout">
                        {this.renderBreadCrumb()}
                        <br />
                        <div className="containerInner">
                            <Layout>
                                <Layout.Sider width={200} style={{ background: '#fff' }}>
                                    <Menu
                                        mode="inline"
                                        defaultSelectedKeys={['tokens']}

                                        style={{ height: '100%' }}
                                    >



                                        <Menu.Item key="sercurity">
                                            <LinkO2O {...this.props} to={{ pathname: "/security/2fa-security" }}>Two-Factor Auth </LinkO2O>
                                        </Menu.Item>
                                        <Menu.Item key="change-password">
                                            <LinkO2O {...this.props} to={{ pathname: "/security/change-password" }}>Change Password</LinkO2O>
                                        </Menu.Item>
                                        <Menu.Item key="api">
                                            <LinkO2O {...this.props} to={{ pathname: "/security/my-account-api" }}>API Account</LinkO2O>
                                        </Menu.Item>
                                        <Menu.Item key="tokens"><LinkO2O {...this.props} to={{ pathname: "/security/my-account-tokens" }}>Token</LinkO2O></Menu.Item>


                                    </Menu>

                                </Layout.Sider>
                                <Layout.Content style={{ paddingLeft: 20 }}>
                                    <Row>
                                        <Col xs={24}>

                                            <Row>
                                                <Col xs={24} lg={24}>
                                                    <p>
                                                        <Typography.Title level={3}>Token</Typography.Title>
                                                    </p>
                                                    <Table
                                                        onChange={this.handleTableChange}
                                                        size="small"
                                                        key="id"
                                                        // rowSelection={rowSelection}
                                                        style={{ marginTop: 20, fontSize: 12 }}
                                                        columns={this.state.columns}
                                                        dataSource={this.props.userTokens.results.content}
                                                        pagination={this.state.pagination}
                                                        loading={this.props.ui.isLoading}
                                                        expandedRowRender={(record: TypeUserToken) => <p style={{ margin: 0 }}>

                                                            <Row gutter={[6, 6]}>
                                                                <Descriptions column={1} title="Detail">
                                                                    <Descriptions.Item label="Id">{record.id || "--"}</Descriptions.Item>

                                                                    <Descriptions.Item label="Token">{record.token || "--"}</Descriptions.Item>
                                                                    <Descriptions.Item label="Api key">{record.apiKey || "--"}</Descriptions.Item>
                                                                    <Descriptions.Item label="Ip Address">{record.ipAddress || "--"}</Descriptions.Item>
                                                                    <Descriptions.Item label="User Agent">{record.userAgent || "--"}</Descriptions.Item>
                                                                    <Descriptions.Item label="Expired At">{
                                                                        record.expiredAt ? <FormattedDate value={new Date(record.expiredAt)}
                                                                            year='numeric'
                                                                            month='2-digit'
                                                                            day='2-digit'
                                                                            hour="2-digit"
                                                                            minute="2-digit"
                                                                            second="2-digit"
                                                                        /> : "--"
                                                                    }</Descriptions.Item>
                                                                    <Descriptions.Item label="Last Accessed At">{
                                                                        record.lastAccessedAt ? <FormattedDate value={new Date(record.lastAccessedAt)}
                                                                            year='numeric'
                                                                            month='2-digit'
                                                                            day='2-digit'
                                                                            hour="2-digit"
                                                                            minute="2-digit"
                                                                            second="2-digit"
                                                                        /> : "--"
                                                                    }</Descriptions.Item>
                                                                    <Descriptions.Item label="Location">{record.geoLocation || '--'}</Descriptions.Item>


                                                                </Descriptions>
                                                            </Row>

                                                        </p>}
                                                        scroll={{ x: 'calc(500px + 50%)' }}
                                                    />
                                                </Col>
                                            </Row>



                                        </Col>
                                    </Row>

                                </Layout.Content>
                            </Layout>

                        </div>
                    </div>

                </div>
            </DashboardPageLayout>
        )
    }

    render() {
        return (
            this.renderTheme()
        );
    }
}
