import { Breadcrumb, Button, Col, Divider, Icon, Input, Row, Select, Table, Tag, Tooltip, Typography, notification } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import { ColumnProps } from 'antd/lib/table';
import _, { get, isArray, upperCase } from 'lodash';
import moment from 'moment';
import { CanAccess, InfoList, LongTextTd, O2OConfirmDelete, O2OCustomPaging, PANGO_PERMISSIONS } from 'o2o_layout';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useAsyncRetry } from 'react-use';
import { BIGDATA_CONSTANT } from '../../../common/constant';
import { checkPermission, getCurrentURL, getPrimaryColor } from '../../../common/helper';
import { DashboardPageLayout } from '../../../layout/dashboard/DashboardPageLayout';
import { Methods } from '../../../libs/api/Methods';
import { IState } from '../../../redux/reducer';
import { useSelector } from '../../../redux/store';
import { MetaHeader } from '../../../shares/MetaHeader';
import { O2OFormItem } from '../../../shares/O2OFormItem';
import { TagPrimaryColor } from '../../../shares/TagPrimaryColor';
import { CommonDAL } from '../../../shares/dal/CommonDAL';
import { DrawerInviteUser } from './DrawerInviteUser';
import { InvationUserDAL, InvationUsers, TypeInvationUser } from './InvationUserDAL';
import { IPropsInviteUsertToOrgComponent } from './InviteUserToOrgContainer';




interface IFilterInviteUsertToOrgProps extends FormComponentProps {
    onSearch: (payload: any) => void
    loading: boolean
}

const FilterInviteUsertToOrg = Form.create<IFilterInviteUsertToOrgProps>()(
    ({ form, onSearch, loading }: IFilterInviteUsertToOrgProps) => {


        const onSubmit = () => {
            form.validateFields((err: any, values: any) => {
                onSearch(values)
            })
        }

        return <Form hideRequiredMark colon={false}>
            <Row gutter={[5, 5]}>
                <Col style={{ zIndex: 20 }} sm={24} lg={12} xl={8} xxl={7}>

                    <O2OFormItem label="Invited by">
                        {form.getFieldDecorator("invitedBy", {

                        })(<Input placeholder='Enter' allowClear onPressEnter={onSubmit} />)}
                    </O2OFormItem>

                </Col>
                <Col style={{ zIndex: 20 }} sm={24} lg={8} xl={4} xxl={3}>
                    <O2OFormItem label="Status">
                        {form.getFieldDecorator("status", {

                        })(<Select placeholder="Select" showArrow allowClear>
                            <Select.Option value={"Pending"}>Pending</Select.Option>
                            <Select.Option value={"Rejected"}>Rejected</Select.Option>
                            <Select.Option value={"Accepted"}>Accepted</Select.Option>
                            <Select.Option value={"Approved"}>Approved</Select.Option>
                        </Select>)}
                    </O2OFormItem>
                </Col>
                <Col>
                    <O2OFormItem label=" ">
                        <Button loading={loading} onClick={onSubmit} icon='search' type='primary'>Search</Button>
                    </O2OFormItem>
                </Col>
            </Row>
        </Form>
    }
)


export const useCheckEnableInvitation = ({ commonActionFromDALV2 }: { commonActionFromDALV2: any }) => {
    const [invitationUsersToJoin, setInvitationUsersToJoin] = React.useState(false as boolean)

    const load = async () => {
        const result = commonActionFromDALV2(CommonDAL.buildURL,
            BIGDATA_CONSTANT.url.api + `orgs/config/general-settings/get`,
            {},
            Methods.GET
        )
        try {
            const response: any = await result;
            if (response.success) {
                setInvitationUsersToJoin(get(response, 'result.invitationUsersToJoin', false))
            } else {
                setInvitationUsersToJoin(false)
            }
        } catch (error) {
            setInvitationUsersToJoin(false)
        }
    }

    React.useEffect(() => {
        load()
    }, [])

    return { invitationUsersToJoin }

}



const initDataCriteria: any = {
    loading: false,
    payload: { page: 0, pageSize: 10 },
    type: ""
}
const SEARCH_INVATION_LETTERS = "SEARCH_INVATION_LETTERS"

const criteriaReducer = (state = initDataCriteria, action: any) => {
    switch (action.type) {
        case SEARCH_INVATION_LETTERS:
            return { ...state, ...action }

        default:
            throw new Error();
    }
}


export const InviteUsertToOrgComponent = (props: IPropsInviteUsertToOrgComponent) => {

    const [criteria, dispatchCriteria] = React.useReducer(criteriaReducer, initDataCriteria)

    const uiSetting = useSelector((state: IState) => state.uiSetting)


    const load = async () => {
        const data: Promise<InvationUsers> = await props.commonActionFromDAL(InvationUserDAL, "searchInvationsClientAdmin", criteria.payload)
        try {
            const result = await data;
            return result
        } catch (error) {
            return new InvationUsers()
        }
    }

    const stateInvationUsers = useAsyncRetry(async () => {
        const data = await load();
        return data

    }, [criteria])

    const invationUsers = stateInvationUsers.value ? stateInvationUsers.value : new InvationUsers();

    const { invitationUsersToJoin } = useCheckEnableInvitation({
        commonActionFromDALV2: props.commonActionFromDALV2
    })

    React.useEffect(() => {
        props.updateMetaTitle({ title: BIGDATA_CONSTANT.trackTitle.ActInvivation })
        let timer = setTimeout(() => {
            props.updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.ActInvivation,
                title: BIGDATA_CONSTANT.trackTitle.ActInvivation,
                app: "ACCOUNT",
                url: getCurrentURL(props.location)
            })
            clearTimeout(timer)
        }, 5000)
        return () => {
            clearTimeout(timer)
        }
    }, [])

    const columns: ColumnProps<{}>[] = [
        {
            title: '#',
            sorter: false,
            width: 40,
            ellipsis: true,
            key: 'index',
            align: 'center',
            render: (text: any, record: any, index: number) => {
                return index + 1
            }
        }, {
            title: "Status",
            ellipsis: true,
            width: 110,
            key: 'status',
            render: (text: any, record: TypeInvationUser, index: number) => {
                return <TagPrimaryColor uiSetting={uiSetting}>{upperCase(record.status)}</TagPrimaryColor>
            }
        },
        {
            title: "Invited Time",
            ellipsis: true,
            width: 120,
            key: 'invited-time',
            render: (text: any, record: TypeInvationUser, index: number) => {
                return record.createdAt ? <Tooltip title={<>{moment(record.createdAt).format('LLLL')}</>} >
                    {moment(record.createdAt).fromNow()}
                </Tooltip> : "--/--"
            }
        },
        {
            title: "Invited By",
            ellipsis: true,
            width: 200,
            key: 'invited-time',
            render: (text: any, record: TypeInvationUser, index: number) => {
                return <LongTextTd text={record.invitedBy} />
            }
        },
        {
            title: "Join Organization",
            ellipsis: true,
            width: 250,
            key: 'company',
            render: (text: any, record: TypeInvationUser, index: number) => {
                return get(invationUsers, `organizations.${record.orgId}.name`, '--/--')
            }
        },
        {
            title: "Role",
            width: 250,
            key: 'role',
            render: (text: any, record: TypeInvationUser, index: number) => {
                return isArray(record.roles) && record.roles.map((role: string) => {
                    return <Tag>{role}</Tag>
                })
            }
        },
        {
            title: <span>Invitation Expiration <Tooltip title="You will not able to accept / reject the invitation after this time"><Icon type='question-circle' /></Tooltip></span>,
            width: 160,
            ellipsis: true,
            key: 'expired-time',
            render: (text: any, record: TypeInvationUser, index: number) => {
                return record.expiredAt ? <Tooltip title={<>{moment(record.expiredAt).format('LLLL')}</>} >
                    {moment(record.expiredAt).fromNow()}
                </Tooltip> : "--/--"
            }
        },
        {
            title: <span>Join Expiration <Tooltip title="You will not able to login to the organization after this time"><Icon type='question-circle' /></Tooltip></span>,
            width: 160,
            ellipsis: true,
            key: 'expired-time',
            render: (text: any, record: TypeInvationUser, index: number) => {
                return record.joinedOrgExpiredAt ? <Tooltip title={<>{moment(record.joinedOrgExpiredAt).format('LLLL')}</>} >
                    {moment(record.joinedOrgExpiredAt).fromNow()}
                </Tooltip> : "Never Expired"
            }
        },
        {
            title: "Reason",
            ellipsis: true,
            key: 'reason',
            render: (text: any, record: TypeInvationUser, index: number) => {
                return <LongTextTd text={record.reason}/>
            }
        },
        {
            title: "Action",
            ellipsis: true,
            width: 120,
            fixed: "right",
            key: 'action',
            render: (text: any, record: TypeInvationUser, index: number) => {
                return <React.Fragment>

                    {
                        record.status === "Accepted" ?
                            <Button disabled type='link' size='small'>
                                <Icon type='check' />
                                Accept
                            </Button> :
                            <O2OConfirmDelete
                                confirm={true}
                                text="Accept"
                                size='small'
                                type='link'
                                confirmText="Are you sure to accept this invitation?"
                                icon="check"
                                toolTipText="Accept"
                                disabled={record.status === "Pending" ? false : true}
                                callApi={(setLoading: any) => {
                                    setLoading(true)
                                    props.commonActionFromDAL(InvationUserDAL, "acceptInvitation", record.id)
                                        .then((e: any) => {
                                            setLoading(false)
                                            if (e.success) {
                                                // notification.success({
                                                //     message: "Success",
                                                //     description: "Accept invitation successfully."
                                                // })

                                                notification.success({
                                                    message: BIGDATA_CONSTANT.message.default.title.success,
                                                    description: "Accept invitation successfully.",
                                                    icon: <Icon type="check-circle" style={{ color: getPrimaryColor(uiSetting) }} />
                                                })
                                                setTimeout(() => {
                                                    stateInvationUsers.retry()
                                                }, 1000);
                                            } else {
                                                notification.error({
                                                    message: BIGDATA_CONSTANT.message.default.title.error,
                                                    description: _.get(e, "message", "Accept invitation failed.")
                                                })
                                            }
                                        })
                                }}
                            />
                    }

                    <Divider type='vertical' />

                    {
                        record.status === "Accepted" ?
                            <Button disabled type='link' size='small'>
                                <Icon type='close' />
                                Reject
                            </Button> :
                            <O2OConfirmDelete
                                confirm={true}
                                text="Reject"
                                size='small'
                                type='link'
                                icon="close"
                                toolTipText="Reject"
                                confirmText='Are you sure to reject this invitation?'
                                disabled={record.status === "Pending" ? false : true}
                                callApi={(setLoading: any) => {
                                    setLoading(true)
                                    props.commonActionFromDAL(InvationUserDAL, "rejectInvitation", record.id)
                                        .then((e: any) => {
                                            setLoading(false)
                                            if (e.success) {
                                                // notification.success({
                                                //     message: "Success",
                                                //     description: "Reject invitation successfully."
                                                // })
                                                notification.success({
                                                    message: BIGDATA_CONSTANT.message.default.title.success,
                                                    description: "Reject invitation successfully.",
                                                    icon: <Icon type="check-circle" style={{ color: getPrimaryColor(uiSetting) }} />
                                                })
                                                setTimeout(() => {
                                                    stateInvationUsers.retry()
                                                }, 1000);
                                            } else {
                                                notification.error({
                                                    message: BIGDATA_CONSTANT.message.default.title.error,
                                                    description: _.get(e, "message", "Reject invitation failed.")
                                                })
                                            }
                                        })
                                }}
                            />
                    }



                </React.Fragment>
            }
        }
    ]

    const renderBreadCrumb = () => {
        return (
            <Row type="flex" justify="space-between" align="middle" style={{ marginTop: 20 }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/">Home</Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>Activity</Breadcrumb.Item>
                    <Breadcrumb.Item>Invitation</Breadcrumb.Item>
                </Breadcrumb>

            </Row>

        )
    }

    const permissionInviteUserJoinOrg: boolean = checkPermission(PANGO_PERMISSIONS.permissions.InviteUser.key, props.authen.permission)
    const [visibleInvite, setVisibleInvite] = React.useState<boolean>(false)

    const renderTheme = () => {
        return (
            <DashboardPageLayout  {...props} selectedLeftNav="invitation" selectedSubMenu="activity" >
                <div className="org-page">

                    <MetaHeader title={props.ui.meta.title} />

                    <div className="contentLayout">
                        {renderBreadCrumb()}
                        <br />
                        <div className="containerInner">

                            <Row gutter={[5, 5]} type='flex'>

                                <Col xs={24}>
                                    <Typography.Title level={4}>
                                        Invitation
                                    </Typography.Title>
                                </Col>

                                <Col xs={24}>
                                    <FilterInviteUsertToOrg
                                        loading={stateInvationUsers.loading}
                                        onSearch={(payload: Object) => {
                                            dispatchCriteria({
                                                loading: false,
                                                payload: { ...criteria.payload, ...{ page: 0 }, ...payload },
                                                type: SEARCH_INVATION_LETTERS,
                                            });
                                        }}
                                    />
                                </Col>

                                <Col xs={24}>
                                    <InfoList
                                        loading={stateInvationUsers.loading}
                                        list={invationUsers}
                                        refresh={stateInvationUsers.retry}
                                        right={
                                            <>
                                                {invitationUsersToJoin && (
                                                    <CanAccess permission={["ADMIN"]} userRoles={props.user.orgCtx ? props.user.orgCtx.roles : []} >
                                                        <Tooltip title={permissionInviteUserJoinOrg ? <>Invite an user to join <br /> your organization</> : "Missing Invite Permission"}>
                                                            <Button disabled={!permissionInviteUserJoinOrg || !invitationUsersToJoin} type="primary" icon="usergroup-add" onClick={() => {
                                                                setVisibleInvite(true)
                                                            }}>Invite</Button>
                                                        </Tooltip>
                                                    </CanAccess>
                                                )}
                                            </>

                                        }

                                    />
                                </Col>


                                <Col xs={24}>
                                    <Table
                                        loading={stateInvationUsers.loading}
                                        columns={columns}
                                        dataSource={invationUsers.results.content}
                                        rowKey="id"
                                        size="small"
                                        scroll={{ x: 'calc(1000px + 50%)' }}
                                        pagination={false}

                                    />
                                </Col>

                                <Col xs={24}>
                                    <O2OCustomPaging
                                        list={invationUsers}
                                        onChange={(e: number) => {
                                            dispatchCriteria({
                                                loading: false,
                                                payload: { ...criteria.payload, ...({ page: e - 1 } as any) },
                                                type: SEARCH_INVATION_LETTERS,
                                            });
                                        }}
                                        onChangePageSize={(e: number) => {
                                            dispatchCriteria({
                                                loading: false,
                                                payload: { ...criteria.payload, ...({ page: 0, pageSize: e } as any) },
                                                type: SEARCH_INVATION_LETTERS,
                                            });
                                        }}
                                    />
                                </Col>

                                <DrawerInviteUser
                                    commonActionFromDALV2={props.commonActionFromDALV2}
                                    visible={visibleInvite}
                                    onClose={() => {
                                        setVisibleInvite(false)
                                    }}
                                    commonActionFromDAL={props.commonActionFromDAL}
                                />


                            </Row>


                        </div>
                    </div>

                </div>
            </DashboardPageLayout>
        )
    }



    const render = () => {
        return (
            <React.Fragment>
                {renderTheme()}
            </React.Fragment>

        );
    }

    return render()
}

