import React from "react";
import { LocaleProvider, Menu, Icon, Button, Popover, Avatar, Row, Col, Divider, Typography, Card, notification } from 'antd';
import viVn from 'antd/lib/locale-provider/vi_VN';
import enEn from 'antd/lib/locale-provider/en_US';
import { DashboardLayout, DashboardPropsType, FooterO2OComponent, AvartarMenuPopover, } from "o2o_layout";
import { XDMAIcon, XAccIcon, XCDPIcon, XConsoleIcon, NotificationCmp, NotificationCritical } from "o2o_layout/lib/common"
import { IState } from "../../redux/reducer";

import { connect } from 'react-redux';
import { UI } from "../../redux/model";
import { showSiderMainLayout, updateLocale, typeUpdateLocate, updateBreakpoint, typeUpdateBreakpoint, setGlobalLoading, commonActionFromDAL, commonActionFromDALV2, showCommonError } from '../../common/actions/CommonAction';
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import './dashboard.scss'
import { canAccess, checkPermission } from '../../common/helper';

import { Location, History } from "history";
import queryString from 'query-string';
import { TypeLangs } from '../../redux/reducer/localeReducer';
import { LinkO2O } from "../../common/LinkO2O";
import Scrollbars from 'react-custom-scrollbars';
import { UISetting, User } from 'o2o_layout/lib/common/model';


import { userLogout, userLogoutAll } from '../../modules/login/actions/LoginAction';

import PubSub from 'pubsub-js'
import { extendToken } from '../../modules/users/ConsoleUsersAction';
import { getUISettingCtx, getUIDataSettingCtx } from '../../modules/setting/action/UISettingAction';
import { BIGDATA_CONSTANT } from '../../common/constant';
import { getPrimaryColor } from "../../common/helper"
import SockJsClient from "../../shares/SockJsClient";
import { UserDAL } from '../../dal/UserDAL';
import { Authen } from '../../redux/model/authen';
import { DrawerQuickAccessComponent } from "o2o_common_ui";
import { XHappyIcon } from "../../shares/DMPIcon";
import DrawerCommonError from "../../shares/CommonError";
import { useDispatch, useSelector } from "../../redux/store";
import { InternalPermissionDAL } from "../../modules/users/internal_permission/DAL/InternalPermissionDAL";
import { OWNER_PERMISSION, IActionUI } from "../../redux/reducer/uiReducer";
import _ from "lodash";
import ExpandableSupportButton from "../../shares/expandable-support-button/ExpandaleSupportButton";
import { DrawerSupport } from "../../shares/send-ticket-caresoft/DrawerSupport";


function mapStateToProps(rootState: IState) {
    return {
        ui: rootState.ui,
        locale: rootState.locale,
        user: rootState.user,
        uiSetting: rootState.uiSetting,
        authen: rootState.authen
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        showSiderMainLayout: bindActionCreators(showSiderMainLayout, dispatch),
        updateLocale: bindActionCreators(updateLocale, dispatch),
        updateBreakpoint: bindActionCreators(updateBreakpoint, dispatch),
        userLogout: bindActionCreators(userLogout, dispatch),
        userLogoutAll: bindActionCreators(userLogoutAll, dispatch),
        setGlobalLoading: bindActionCreators(setGlobalLoading, dispatch),
        extendToken: bindActionCreators(extendToken, dispatch),
        getUISettingCtx: bindActionCreators(getUISettingCtx, dispatch),
        getUIDataSettingCtx: bindActionCreators(getUIDataSettingCtx, dispatch),
        commonActionFromDAL: bindActionCreators(commonActionFromDAL, dispatch),
        commonActionFromDALV2: bindActionCreators(commonActionFromDALV2, dispatch),
        showCommonError: bindActionCreators(showCommonError, dispatch),
    };
}


export interface DashboardProps extends DashboardPropsType {
    ui?: UI,
    showSiderMainLayout?: any,
    updateLocale: typeUpdateLocate,
    locale: any,
    user?: User,
    selectedLeftNav: any,
    selectedSubMenu?: any,
    location: Location,
    history: History,
    updateBreakpoint: typeUpdateBreakpoint,
    landing?: boolean,
    userLogout: any,
    userLogoutAll: any,
    setGlobalLoading: any,
    extendToken: any,
    getUISettingCtx: any,
    uiSetting: UISetting,
    getUIDataSettingCtx: any
    commonActionFromDAL: any
    commonActionFromDALV2: any
    authen: Authen
    showCommonError: any
}


export class DashboardPageComponent extends React.Component<DashboardProps, any> {

    debounceTimeout: any

    constructor(props: DashboardProps) {
        super(props);
        this.state = {
            isVisible: false,
            visibleSupport: false,
            breakpoint: this.getScreenSize(),
            heightMenuLeft: window.innerHeight,
            companyName: 'ByteTech',
            isExtending: false,
            isLoadingExtend: false,
            isVisibleBookMark: false
        };
        this.handleResize = this.handleResize.bind(this);
        this.handleResize()
    }
    toggleDrawerBookmark = () => {
        this.setState((prevState: any) => {
            return {
                isVisibleBookMark: !prevState.isVisibleBookMark
            }
        })
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }


    componentDidMount() {
        const userRoles = this.props.user ? (this.props.user.orgCtx ? this.props.user.orgCtx.roles : []) : [];
        this.updateLang();
        window.addEventListener('resize', this.handleResize);
        PubSub.subscribe('EXTEND_TOKEN', this.extendToken);

        if (this.props.authen.changePassword) {
            this.props.history.push({
                pathname: '/security/change-password'
            })
        }
        if (canAccess(["SUPER_ADMIN"], userRoles)) {
            this.loadOwnerHasPermission();
        }
    }

    loadOwnerHasPermission = async () => {
        const dispatch = useDispatch();
        const result = this.props.commonActionFromDALV2(InternalPermissionDAL.hasOwnerPermission)
        try {
            const response = await result
            dispatch({
                type: OWNER_PERMISSION,
                ownerPermission: response.success,
            } as IActionUI);
        } catch (error) {
            dispatch({
                type: OWNER_PERMISSION,
                ownerPermission: false,
            } as IActionUI);
        }
    }

    openExtendPopup = () => {
        const btn = (
            <Button type="primary" disabled={this.state.isLoadingExtend} size="small" onClick={() => {

                this.setState({
                    isLoadingExtend: true
                }, () => {
                    notification.open({
                        key: "extend_noti",
                        message: 'Your token has been expending. wait a second',
                        description: 'loading...',
                    });
                    this.props.extendToken()
                        .then(() => {
                            notification.open({
                                key: "extend_noti",
                                message: 'Your token has been extended',
                                description: '',
                            });
                            setTimeout(() => {

                                notification.close("extend_noti")
                            }, 1000)

                            this.setState({
                                isLoadingExtend: false
                            })
                        })
                })

            }}>
                Extend
            </Button>
        );

        const args = {
            message: 'Your session is expiring soon',
            description: "Click the button to extend your session",
            duration: 0,
            key: "extend_noti",
            btn,
            onClose: () => {
                this.setState({
                    isExtending: false
                })
            }
        };

        notification.open(args);
    };

    extendToken = (a: any, b: any) => {

        if (b) {
            if (!this.state.isExtending) {

                this.setState({
                    isLoadingExtend: true,
                    isExtending: true
                }, () => {

                    this.props.extendToken()
                        .then(() => {
                            this.setState({
                                isLoadingExtend: false,
                                isExtending: false
                            })
                        })
                        .catch(() => {
                            this.setState({
                                isLoadingExtend: false,
                                isExtending: false
                            })
                        })
                })
                // this.openExtendPopup();
            }
        }
    }

    getScreenSize = () => {

        const { innerWidth } = window;
        let breakpoint = 'xs';

        if (innerWidth >= 1024) {
            breakpoint = 'lg'
        }
        else if (innerWidth >= 768) {
            breakpoint = 'md'
        }
        else if (innerWidth >= 400) {
            breakpoint = 'sm'
        }

        return breakpoint;
    };

    handleResize = () => {
        clearTimeout(this.debounceTimeout);

        this.debounceTimeout = setTimeout(() => {
            const breakpoint = this.getScreenSize();
            this.setState({ breakpoint: breakpoint, heightMenuLeft: window.innerHeight });
            this.props.updateBreakpoint(breakpoint, window.innerHeight)

            if ((this.props.ui && this.props.ui.showSider == true) && (breakpoint == 'xs' || breakpoint == 'sm' || breakpoint == 'md')) {
                this.props.showSiderMainLayout(false)
            }

        }, 250);
    };

    getQuery = () => {
        const q = queryString.parse(this.props.location.search)
        return q
    }

    updateLang = () => {
        let qString = this.props.location.search;
        const parseQuery = queryString.parse(qString)

        if (parseQuery.lang) {
            // debugger
            const lang: TypeLangs = parseQuery.lang == 'vi' || parseQuery.lang == 'en' ? parseQuery.lang : 'vi';

            this.props.updateLocale(lang)
        }
    }

    successChangeLang = (key: string) => {
        let qString = this.props.location.search;
        const parseQuery = queryString.parse(qString)
        parseQuery.lang = key;
        qString = queryString.stringify(parseQuery);
        this.props.history.push({ pathname: this.props.location.pathname, search: qString })
    }

    contentAvatar = () =>
    (<AvartarMenuPopover
        avatarSrc={this.props.user ? this.props.user.avatar : ''}
        accountUrl=""
        appUrl=""
        user={this.props.user}
        overrideSignOut={(
            <React.Fragment>
                <Col span={24} style={{ marginBottom: 10 }}>
                    <Button size="small" loading={this.props.ui ? this.props.ui.isLoading : false} block type="link" onClick={() => {
                        this.props.setGlobalLoading(true)

                        this.props.userLogout()
                            .then(() => {
                                this.props.setGlobalLoading(false)
                                this.props.history.replace("/login")
                            })
                    }}> Logout</Button>
                </Col>

                <Divider />
                <Col span={24} style={{ marginBottom: 10 }} >
                    <Button size="small" block type="link" onClick={() => {
                        this.props.setGlobalLoading(true)

                        this.props.userLogoutAll()
                            .then(() => {
                                this.props.setGlobalLoading(false)
                                this.props.history.replace("/login")
                            })
                    }}>
                        Logout all devices
                    </Button>
                </Col>
            </React.Fragment>
        )}
        urlSelectCompnany={
            <LinkO2O  {...this.props} to={{ pathname: '/companies/select' }}>
                {this.props.user ? (this.props.user.orgCtx ? this.props.user.orgCtx.organization.name : "") : ''} <Icon type="right" />
            </LinkO2O>
        }
        urlSelectProfile={
            <div style={{ marginTop: 10, fontSize: 15 }}>
                <LinkO2O  {...this.props} to={{ pathname: '/my-account-info' }}>My Profile</LinkO2O>
            </div>

        }
    />)



    calculateTextSelectTrackingId = (text: string) => {

        if (this.props.ui) {
            if (this.props.ui.breakpoint == 'xs' || this.props.ui.breakpoint == 'sm') {
                return 'Select Tracking'
            } else if (this.props.ui.breakpoint == 'lg') {
                return text
            }
        } else {
            return text
        }
        return text
    }



    contentApplication = () => {
        return (

            <Row className="popover-o2o-avatar" style={{ textAlign: "center", padding: '10px' }} gutter={[16, 16]} align="middle" justify="start" type="flex">

                <Col style={{ marginBottom: 10 }}>
                    <Card bordered={true} hoverable style={{ height: 110, width: 80 }} size="small">
                        <a href={process.env.REACT_APP_ACCOUNT_APP} target="_blank">
                            <Icon component={() => {
                                return (
                                    <XAccIcon
                                        fillBackground={this.props.uiSetting.themeColor ? this.props.uiSetting.themeColor : BIGDATA_CONSTANT.primaryColor}
                                    />
                                )
                            }} style={{ fontSize: 40, marginBottom: 10 }} />
                            <p>
                                <Typography.Text strong style={{ fontSize: 10, color: '#000' }} >ACC</Typography.Text>
                            </p>
                        </a>
                    </Card>
                </Col>



                <Col style={{ marginBottom: 10 }}>
                    <Card bordered={false} hoverable style={{ height: 110, width: 80 }} size="small" >

                        <a href={process.env.REACT_APP_CDP_APP} target="_blank">
                            <Icon component={() => {
                                return (
                                    <XCDPIcon
                                        fillBackground={this.props.uiSetting.themeColor ? this.props.uiSetting.themeColor : BIGDATA_CONSTANT.primaryColor}
                                    />
                                )
                            }} style={{ fontSize: 40, marginBottom: 10 }} />
                            <p>
                                <Typography.Text strong style={{ fontSize: 10, color: '#000' }}>CDP</Typography.Text>
                            </p>
                        </a>
                    </Card>
                </Col>

                <Col style={{ marginBottom: 10 }}>
                    <Card bordered={false} hoverable style={{ height: 110, width: 80 }} size="small">
                        <a href={process.env.REACT_APP_CON_APP} target="_blank">
                            <Icon component={() => {
                                return (
                                    <XConsoleIcon
                                        fillBackground={this.props.uiSetting.themeColor ? this.props.uiSetting.themeColor : BIGDATA_CONSTANT.primaryColor}
                                    />
                                )
                            }} style={{ fontSize: 40, marginBottom: 10 }} />
                            <p>
                                <Typography.Text strong style={{ fontSize: 10, color: '#000' }} >CON</Typography.Text>
                            </p>
                        </a>
                    </Card>
                </Col>

                <Col style={{ marginBottom: 10 }}>
                    <Card bordered={false} hoverable style={{ height: 110, width: 80 }} size="small" >

                        <a href={process.env.REACT_APP_DMA_APP} target="_blank">
                            <Icon component={() => {
                                return (
                                    <XDMAIcon
                                        fillBackground={this.props.uiSetting.themeColor ? this.props.uiSetting.themeColor : BIGDATA_CONSTANT.primaryColor}
                                    />
                                )
                            }} style={{ fontSize: 40, marginBottom: 10 }} />
                            <p>
                                <Typography.Text strong style={{ fontSize: 10, color: '#000' }}>DMA</Typography.Text>
                            </p>
                        </a>
                    </Card>
                </Col>

                <Col style={{ marginBottom: 10 }}>
                    <Card bordered={false} hoverable style={{ height: 110, width: 80 }} size="small" >

                        <a href={process.env.REACT_APP_ACT_APP} target="_blank">
                            <Icon component={() => {
                                return (
                                    <XHappyIcon
                                        fillBackground={this.props.uiSetting.themeColor ? this.props.uiSetting.themeColor : BIGDATA_CONSTANT.primaryColor}
                                    />
                                )
                            }} style={{ fontSize: 40, marginBottom: 10 }} />
                            <p>
                                <Typography.Text strong style={{ fontSize: 10, color: '#000' }}>ACT</Typography.Text>
                            </p>
                        </a>
                    </Card>
                </Col>


            </Row>

        )
    }

    renderRightZone = () => {
        return (
            <Row type="flex" justify="end">
                {/* <Col>
                    <Button onClick={() => {
                        this.setState({
                            visibleSupport: true
                        })
                    }}>
                        <Avatar icon="question" style={{ background: "transparent", border: "1px solid #fff" }} ></Avatar>
                    </Button>
                </Col> */}

                <Col>
                    <Button onClick={this.toggleDrawerBookmark}>
                        <Avatar src='https://res.cloudinary.com/bytetech-jsc/image/upload/v1660833977/pangoCDP/default/pango_quick_path_f36rci.svg' style={{ background: "transparent" }} ></Avatar>
                    </Button>
                </Col>

                <Col>
                    <NotificationCmp viewAllText="View all" urlAccount={`${process.env.REACT_APP_CON_APP}system/notifications`} userDAL={UserDAL} notifications={this.state.noti} commontActionFromDAL={this.props.commonActionFromDAL} />
                </Col>

                <Col>
                    <Popover className="o2o-popover" placement="bottom" content={this.contentApplication()} title="" trigger="click">
                        <Button>
                            <Avatar icon="appstore" style={{ background: "#956DD1" }} ></Avatar>
                        </Button>
                    </Popover>
                </Col>

                <Col>
                    <Popover className="o2o-popover" overlayClassName="o2o-popover-inner" placement="bottomRight" content={this.contentAvatar()} title="" trigger="click">
                        <Button>
                            <Avatar src={this.props.user && this.props.user.avatar ? this.props.user.avatar : ''} icon="user" style={{ background: "#956DD1", border: "1px solid #fff" }} ></Avatar>
                        </Button>
                    </Popover>
                </Col>
            </Row>
        )
    }

    renderLeftZone = (userRoles: any, lang: any) => {
        const hasOwnerPermission = useSelector((state: IState) => state.ui.ownerPermission)
        return (
            <Menu theme="light"
                defaultSelectedKeys={[this.props.selectedLeftNav]}
                defaultOpenKeys={[this.props.selectedSubMenu]}
                style={{ borderColor: 'transparent', marginBottom: 20 }}
                mode="inline">
                <Menu.Item key="my-info"><Link to={{
                    pathname: "/my-account-info",
                    search: queryString.stringify({ lang: lang })
                }}><Icon type="user" /> Profile</Link>
                </Menu.Item>
                <Menu.Item key="my-security"><Link to={{
                    pathname: "/security/2fa-security",
                    search: queryString.stringify({ lang: lang })
                }}><Icon type="security-scan" /> Security</Link>
                </Menu.Item>




                <Menu.SubMenu
                    key="activity"
                    title={<span><Icon type="solution" /><span>Activity</span></span>}
                >


                    <Menu.Item key="invitation">
                        <LinkO2O to={{ pathname: "/activity/invitation" }} {...this.props}>
                            <Icon type="usergroup-add" />
                            <span>Invitation</span>
                        </LinkO2O>
                    </Menu.Item>

                    <Menu.Item key="exports-history">
                        <LinkO2O to={{ pathname: "/activity/exports-history" }} {...this.props}>
                            <Icon type="export" />
                            <span>Exports History</span>
                        </LinkO2O>
                    </Menu.Item>

                    <Menu.Item key="login-history">
                        <LinkO2O to={{ pathname: "/activity/login-history" }} {...this.props}>
                            <Icon type="solution" />
                            <span>Login Activity</span>
                        </LinkO2O>
                    </Menu.Item>


                </Menu.SubMenu>




                {
                    canAccess(["ADMIN", "SUPER_ADMIN"], userRoles) ? (
                        <Menu.SubMenu
                            key="console"
                            title={<span><Icon type="setting" /><span>Admin</span></span>}
                        >
                            {
                                canAccess(["ADMIN", "SUPER_ADMIN"], userRoles) ? (
                                    <Menu.Item key="organization">
                                        <LinkO2O to={{ pathname: "/organization" }} {...this.props}>
                                            <Icon type="apartment" />
                                            <span>Organization</span>
                                        </LinkO2O>
                                    </Menu.Item>
                                ) : (
                                    null
                                )
                            }

                            <Menu.Item key="13"><Link to={{
                                pathname: "/console-users",
                                search: queryString.stringify({ lang: lang })
                            }} ><Icon type="user" /> Accounts</Link></Menu.Item>
                            {checkPermission("AccountPermission", this.props.authen.permission) && (
                                <Menu.Item key="13"><Link to={{
                                    pathname: "/console-users",
                                    search: queryString.stringify({ lang: lang })
                                }} ><Icon type="user" /> Users</Link></Menu.Item>
                            )}

                            {
                                canAccess(["SUPER_ADMIN", "ADMIN"], userRoles) ? (
                                    <Menu.Item key="group-permision">
                                        <LinkO2O to={{ pathname: "/permission-group" }} {...this.props}>
                                            <Icon type="apartment" />
                                            <span>Permission Group</span>
                                        </LinkO2O>
                                    </Menu.Item>
                                ) : (
                                    null
                                )
                            }

                            {
                                canAccess(["SUPER_ADMIN", "ADMIN"], userRoles) ? (
                                    <Menu.Item key="activity">
                                        <LinkO2O to={{ pathname: "/console-activity" }} {...this.props}>
                                            <Icon type="solution" />
                                            <span>Activities</span>
                                        </LinkO2O>
                                    </Menu.Item>
                                ) : (
                                    null
                                )
                            }

                        </Menu.SubMenu>

                    ) : null

                }
                {
                    canAccess(["SUPER_ADMIN"], userRoles) ? (
                        <Menu.SubMenu
                            key="console-super"
                            title={<span><Icon type="safety" /><span>Super Admin</span></span>}
                        >
                            {
                                canAccess(["SUPER_ADMIN"], userRoles) ? (
                                    <Menu.Item key="12"><Link to={{
                                        pathname: "/console-organizations",
                                        search: queryString.stringify({ lang: lang })
                                    }}><Icon type="apartment" /> Organization</Link>
                                    </Menu.Item>
                                ) : (null)
                            }
                            {
                                canAccess(["SUPER_ADMIN"], userRoles) ? (
                                    <Menu.Item key="super-admin-console-users"><Link to={{
                                        pathname: "/super-admin-console-users",
                                        search: queryString.stringify({ lang: lang })
                                    }}><Icon type="user" /> Accounts</Link>
                                    </Menu.Item>
                                ) : (null)
                            }
                            {
                                canAccess(["SUPER_ADMIN"], userRoles) ? (
                                    <Menu.Item key="permission">
                                        <LinkO2O to={{ pathname: "/console-permission" }} {...this.props}>
                                            <Icon type="apartment" />
                                            <span>Permission</span>
                                        </LinkO2O>
                                    </Menu.Item>
                                ) : (
                                    null
                                )
                            }
                            {
                                canAccess(["SUPER_ADMIN"], userRoles) && hasOwnerPermission ? (
                                    <Menu.Item key="internal_permission">
                                        <LinkO2O to={{ pathname: "/internal-permission" }} {...this.props}>
                                            <Icon type="apartment" />
                                            <span>Internal Permission</span>
                                        </LinkO2O>
                                    </Menu.Item>
                                ) : (
                                    null
                                )
                            }
                            {
                                canAccess(["SUPER_ADMIN"], userRoles) ? (
                                    <Menu.Item key="menu_link_org"><Link to={{
                                        pathname: "/console-link-orgs",
                                        search: queryString.stringify({ lang: lang })
                                    }}><Icon type="link" /> Link Company</Link>
                                    </Menu.Item>
                                ) : (null)
                            }
                            {
                                canAccess(["SUPER_ADMIN"], userRoles) ? (
                                    <Menu.Item key="menu_import"><Link to={{
                                        pathname: "/console-system-setting",
                                        search: queryString.stringify({ lang: lang })
                                    }}><Icon type="setting" /> System Setting</Link>
                                    </Menu.Item>
                                ) : (null)
                            }




                        </Menu.SubMenu>

                    ) : null

                }


            </Menu>

        )
    }

    getCommonError = () => {
        const commonError = useSelector((state: IState) => state.ui.commonError)
        return commonError
    }

    render() {
        const userRoles = this.props.user ? (this.props.user.orgCtx ? this.props.user.orgCtx.roles : []) : [];

        const qString = this.props.location.search;
        const parseQuery = queryString.parse(qString)
        let lang = parseQuery.lang;
        const { landing } = this.props
        return (

            <LocaleProvider locale={this.props.locale ? (this.props.locale.locale == 'en' ? enEn : viVn) : viVn}>
                <React.Fragment>
                    <DashboardLayout
                        showSider={this.props.ui ? this.props.ui.showSider : false}
                        logoNode={
                            this.props.user && _.get(this.props.user, 'orgCtx.organization.imageLink', undefined) ?
                                <Avatar style={{ marginBottom: 10 }} shape={'circle'} size={80} src={_.get(this.props.user, 'orgCtx.organization.imageLink', undefined)} />
                                :
                                <Icon component={() => {
                                    return (
                                        <XAccIcon
                                            fillBackground={getPrimaryColor(this.props.uiSetting)}
                                        />
                                    )
                                }} style={{ fontSize: 80, marginBottom: 10 }} />

                        }
                        showSiderMainLayout={this.props.showSiderMainLayout}
                        logo={this.props.ui ? this.props.ui.logo : null}
                        logoSecond={this.props.ui ? this.props.ui.logoSecond : null}
                        companyName2={this.props.user ? (this.props.user.orgCtx ? this.props.user.orgCtx.organization.name : this.state.companyName) : this.state.companyName}
                        companyName={this.props.user && _.get(this.props.user, 'orgCtx.organization.imageLink', undefined) ? <>
                            <div style={{
                                color: "var(--primary-color)",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                padding: "0px 10px 0px 10px"
                            }}>
                                <span style={{ fontWeight: 400 }}>
                                    {this.props.user ? (this.props.user.orgCtx ? this.props.user.orgCtx.organization.name : this.state.companyName) : this.state.companyName}
                                </span>
                            </div>
                            <div style={{ color: "#242425" }}>
                                Account Management (ACC)
                            </div>
                        </> as any : <div style={{
                            color: "var(--primary-color)",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            padding: "0px 10px 0px 10px"
                        }}>
                            <span style={{ fontWeight: 400 }}>
                                {this.props.user ? (this.props.user.orgCtx ? this.props.user.orgCtx.organization.name : this.state.companyName) : this.state.companyName}
                            </span>
                        </div>}
                        navTopRight={
                            !landing ? (this.renderRightZone()) : null
                        }
                        leftMenu={
                            !landing ? (<Scrollbars autoHide style={{ height: this.state.heightMenuLeft - 185 }}>
                                {this.renderLeftZone(userRoles, lang)}
                            </Scrollbars>) : (
                                <Scrollbars autoHide style={{ height: this.state.heightMenuLeft - 185 }}>
                                    <Menu theme="light"
                                        defaultSelectedKeys={[this.props.selectedLeftNav]}
                                        defaultOpenKeys={[this.props.selectedSubMenu]}
                                        style={{ borderColor: 'transparent', marginBottom: 20 }}
                                        mode="inline">
                                        <Menu.Item key="9">
                                            <Link to="/help"><Icon type="question" />
                                                <span>Help</span></Link>
                                        </Menu.Item>

                                        <Menu.Item key="10">
                                            <Link to="/policy">
                                                <span>Privacy Policy</span></Link>
                                        </Menu.Item>
                                    </Menu>
                                </Scrollbars>
                            )
                        }>

                        <div>
                            <NotificationCritical url={`${process.env.REACT_APP_CON_APP}system/notifications?solve=false`} dal={UserDAL} commonActionFromDAL={this.props.commonActionFromDAL} />
                            {this.props.children}
                        </div>

                        <DrawerSupport
                            commonActionFromDALV2={this.props.commonActionFromDALV2}
                            visible={this.state.visibleSupport}
                            onClose={() => {
                                this.setState({
                                    visibleSupport: false
                                })
                            }}
                        />

                        <DrawerQuickAccessComponent
                            title='QUICK ACCESS'
                            visible={this.state.isVisibleBookMark}
                            onClose={this.toggleDrawerBookmark}
                            commonActionFromDALV2={this.props.commonActionFromDALV2}
                            application='ACCOUNT'
                            api_prefix={BIGDATA_CONSTANT.url.account || ""}
                            baseUrlCDP={process.env.REACT_APP_CDP_APP || ""}
                            baseUrlCON={process.env.REACT_APP_CON_APP || ""}
                            baseUrlDMA={process.env.REACT_APP_DMA_APP || ""}
                            color={getPrimaryColor(this.props.uiSetting)}
                        />

                        <DrawerCommonError
                            visible={this.getCommonError() ? true : false}
                            onClose={() => {
                                this.props.showCommonError(undefined)
                            }} />

                        {this.props.user ? (
                            <SockJsClient url={process.env.REACT_APP_SOCKET_URL} topics={['/topic/global/messages', `/topic/orgs/${this.props.user.orgCtx ? this.props.user.orgCtx.orgId : ""}/messages`, `/topic/users/${this.props.user.orgCtx ? this.props.user.orgCtx.orgId : ""}_${this.props.user.id}/messages`,
                                `/topic/users/${this.props.user.orgCtx ? this.props.user.orgCtx.orgId : ""}_${this.props.user.id}/messages/stats`]}
                                onMessage={(msg: any, header: any) => {
                                    if (this.props.user && header == `/topic/users/${this.props.user.orgCtx ? this.props.user.orgCtx.orgId : ""}_${this.props.user.id}/messages/stats`) {
                                        this.setState({
                                            noti: msg
                                        })

                                    } else {
                                        if (msg) {
                                            notification[msg.messageLevel || "info"]({
                                                message: msg.title || "Title",
                                                description: msg.message,
                                                duration: 0
                                            });
                                        }
                                    }
                                }}
                            />
                        ) : (null)}

                        <ExpandableSupportButton
                            openFormCaresoft={() => {
                                this.setState({
                                    visibleSupport: true
                                })
                            }} />

                    </DashboardLayout>

                    <FooterO2OComponent style={{ float: "right", textAlign: "right" }}></FooterO2OComponent>
                </React.Fragment>
            </LocaleProvider >
        )
    }
}


export const DashboardPageLayout = connect<any, any>(mapStateToProps, mapDispatchToProps)(DashboardPageComponent)