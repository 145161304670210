import * as React from 'react';
import { Breadcrumb, Row, Col, Layout, Menu, Typography, Avatar, Spin, Button, Form, Input, Modal, notification, Icon } from 'antd';
import { Link } from 'react-router-dom';
import CommonTypeProps from '../../../common/CommonTypeProps';
import { DashboardPageLayout } from '../../../layout/dashboard/DashboardPageLayout';
import _ from 'lodash';
import { LinkO2O } from '../../../common/LinkO2O';
import { FormComponentProps } from 'antd/lib/form';
import { BIGDATA_CONSTANT } from '../../../common/constant';
import { getCurrentURL, getPrimaryColor } from '../../../common/helper';
import { MetaHeader } from '../../../shares/MetaHeader';
import { UISetting } from 'o2o_layout/lib/common/model';


interface Props extends CommonTypeProps {
    enable2Fa: any,
    verifyEnable2Fa: any,
    verifyDisable2Fa: any
    uiSetting: UISetting
}

type TypeQR = {
    qrcode: string
    secretKeys: string,
}

interface PropsQR extends FormComponentProps {
    qr: TypeQR,
    loading: boolean,
    status: boolean | undefined,
    submit: any
}

const QRComponent = (props: PropsQR) => {
    const { loading, qr } = props
    const { getFieldDecorator } = props.form

    return (
        <Spin spinning={loading}>
            <Row type="flex" gutter={[20, 20]}>
                <Col xs="24">

                    <Avatar shape="square" size={300} src={qr.qrcode ? `data:image/png;base64,${qr.qrcode}` : ""} />
                    <p>Scan QR code from your application</p>

                    <Form layout="inline" onSubmit={props.submit}>
                        <Form.Item label="">
                            {getFieldDecorator("code", {
                                rules: [{
                                    required: true,
                                    message: "Please Scan QR code from your application"
                                }]
                            })(
                                <Input style={{ width: 300 }} placeholder="Enter Code" />
                            )}

                        </Form.Item>
                        <Form.Item label="">
                            <Button onClick={(e: any) => {
                                props.submit(e, props.form)
                            }} type="primary">Verify</Button>
                        </Form.Item>

                    </Form>

                </Col>
            </Row>
        </Spin>
    )
}

const QRComponentForm = Form.create<PropsQR>()(QRComponent)

export class TwoFaComponent extends React.Component<Props, any> {

    state = {
        enableToTp: false,
        qr: {
            qrcode: "",
            secretKeys: ""
        } as TypeQR,
        loadingQr: false,

    };
    timer: any

    componentWillUnmount(){
        clearTimeout(this.timer)
    }


    componentDidMount = () => {
        this.setState({
            enableToTp: this.props.user.totpEnabled
        })
        this.props.updateMetaTitle({title: BIGDATA_CONSTANT.trackTitle.TwoFa})
        this.timer = setTimeout(() => {
            this.props.updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.TwoFa,
                title: BIGDATA_CONSTANT.trackTitle.TwoFa,
                app: "ACCOUNT",
                url: getCurrentURL(this.props.location)
              })
            clearTimeout(this.timer)
        }, 5000)
    }



    renderBreadCrumb = () => {
        return (
            <Row type="flex" justify="space-between" align="middle" style={{ marginTop: 20 }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <LinkO2O {...this.props} to={{
                            pathname: "/security/2fa-security"
                        }}>Security</LinkO2O>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>2FA-TOTP</Breadcrumb.Item>
                </Breadcrumb>

            </Row>

        )
    }


    submit = (e: any, form: any) => {
        e.preventDefault()
        form.validateFields((err: any, values: any) => {
            if (err) {
                return;
            }

            this.props.setGlobalLoading(true)

            this.props.verifyEnable2Fa(values.code, this.state.qr.secretKeys)
                .then((e: any) => {
                    this.props.setGlobalLoading(false)
                    // message.info(e ? e : "Invalid key")
                    notification.success({
                        message: BIGDATA_CONSTANT.message.default.title.success,
                        description: e ? e : "Invalid key",
                        icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                    })

                    if (e) {
                        setTimeout(() => {
                            window.location.reload()
                        }, 700);
                    }
                })
                .catch(() => {
                    this.props.setGlobalLoading(false)
                    // message.info("Invalid key")
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: "Invalid key"
                    })
                })

        })

    }

    disable = () => {
        this.props.setGlobalLoading(true)
        this.props.verifyDisable2Fa()
            .then((e: any) => {
                this.props.setGlobalLoading(false)
                // message.info(e ? e : "Invalid key")
                notification.success({
                    message: BIGDATA_CONSTANT.message.default.title.success,
                    description: e ? e : "Invalid key",
                    icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                })
                if (e) {
                    setTimeout(() => {
                        window.location.reload()
                    }, 700);
                }

            })
            .catch(() => {
                this.props.setGlobalLoading(false)
                // message.info("Invalid key")
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: "Invalid key"
                })
            })
    }

    formRef: any

    renderTheme() {


        return (
            <DashboardPageLayout  {...this.props} selectedLeftNav="my-security" selectedSubMenu="my-account" >
                <div className="org-page">

                   <MetaHeader title={this.props.ui.meta.title} />

                    <div className="contentLayout">
                        {this.renderBreadCrumb()}
                        <br />
                        <div className="containerInner">
                            <Layout>
                                <Layout.Sider width={200} style={{ background: '#fff' }}>
                                    <Menu
                                        mode="inline"
                                        defaultSelectedKeys={['sercurity']}

                                        style={{ height: '100%' }}
                                    >


                                        <Menu.Item key="sercurity">
                                            <LinkO2O {...this.props} to={{ pathname: "/security/2fa-security" }}>Two-Factor Auth </LinkO2O>
                                        </Menu.Item>
                                        <Menu.Item key="change-password">
                                            <LinkO2O {...this.props} to={{ pathname: "/security/change-password" }}>Change Password</LinkO2O>
                                        </Menu.Item>
                                        <Menu.Item key="api">
                                            <LinkO2O {...this.props} to={{ pathname: "/security/my-account-api" }}>API Account</LinkO2O>
                                        </Menu.Item>
                                        <Menu.Item key="tokens"><LinkO2O {...this.props} to={{ pathname: "/security/my-account-tokens" }}>Token</LinkO2O></Menu.Item>


                                    </Menu>

                                </Layout.Sider>
                                <Layout.Content style={{ paddingLeft: 20, overflow: "hidden" }}>
                                    <Row>
                                        <Col xs={24}>

                                            <Typography.Title level={3}>Two-factor Authentication


                                            </Typography.Title>
                                            <Row gutter={[20, 10]} align="middle" type="flex">
                                                <Col xs={24} lg={18}>
                                                    <Typography.Paragraph>
                                                        <Typography.Text style={{ display: "block", marginBottom: 10, fontSize: 16 }}><b>Secure your account</b></Typography.Text>
                                                        Two-factor authentication adds an extra layer of security to your account. To log in, you'll need to provide a code along with your username and password. This lets us know it's actually you.

                                                    </Typography.Paragraph>


                                                    {this.props.user.totpEnabled != this.state.enableToTp ? (
                                                        <Button type="default" onClick={() => {
                                                            this.setState({
                                                                qr: {
                                                                    qrcode: "",
                                                                    secretKeys: ""
                                                                },
                                                                loadingQr: false,
                                                                enableToTp: this.props.user.totpEnabled
                                                            })
                                                        }}>Cancel Two-factor Authentication</Button>
                                                    ) : (

                                                            <Button.Group>
                                                                <Button type={this.props.user.totpEnabled ? "default" : "primary"}
                                                                style={{
                                                                    cursor: this.props.user.totpEnabled ? "pointer" : "default"
                                                                }}
                                                                    onClick={() => {
                                                                        if (this.props.user.totpEnabled) {
                                                                            this.enable2faSwitch(false)
                                                                        }
                                                                    }}
                                                                >OFF</Button>
                                                                <Button type={!this.props.user.totpEnabled ? "default" : "primary"}
                                                                    style={{
                                                                        cursor: !this.props.user.totpEnabled ? "pointer" : "default"
                                                                    }}
                                                                    onClick={() => {
                                                                        if (!this.props.user.totpEnabled) {
                                                                            this.enable2faSwitch(true)
                                                                        }
                                                                    }}
                                                                >ENABLE</Button>
                                                            </Button.Group>
                                                        )}

                                                </Col>
                                                <Col xs={24} lg={6}>

                                                </Col>
                                                <Col xs={24}>
                                                    {this.state.qr.qrcode ? (
                                                        <QRComponentForm
                                                            submit={this.submit}
                                                            loading={this.state.loadingQr}
                                                            qr={this.state.qr}
                                                            status={this.props.user.totpEnabled}
                                                            wrappedComponentRef={(form: any) => {
                                                                this.formRef = form
                                                            }}
                                                        />
                                                    ) : null}
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row>

                                </Layout.Content>
                            </Layout>

                        </div>
                    </div>

                </div>
            </DashboardPageLayout>
        )
    }

    enable2faSwitch = (ev: boolean) => {
        if (ev == false) {
            Modal.confirm({
                okText: "Confirm",
                title: "Are you sure to disable Two-factor aunthentication?",
                onCancel: () => {
                    this.setState({
                        enableToTp: this.props.user.totpEnabled
                    })
                },
                onOk: () => {
                    this.disable()
                }

            })
        } else {
            this.setState({
                loadingQr: true
            }, () => {
                this.props.enable2Fa()
                    .then((e: any) => {
                        this.setState({
                            qr: Object.assign(this.state.qr, e),
                            loadingQr: false,
                            enableToTp: ev,
                        })
                    })

            })
        }
    }

    render() {
        return (
            this.renderTheme()
        );
    }
}
