import { Avatar, Breadcrumb, Button, Col, Collapse, Divider, Drawer, Form, Icon, Row, Typography, Upload, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { RcFile } from 'antd/lib/upload';
import _, { get, isArray } from 'lodash';
import moment from "moment";
import { TypeUserPermission } from 'o2o_layout/lib/common/model';
import { Methods } from 'o2o_layout/lib/common/rest';
import * as React from 'react';
import { Link } from 'react-router-dom';
import CommonTypeProps from '../../../common/CommonTypeProps';
import { BIGDATA_CONSTANT } from '../../../common/constant';
import { getCurrentURL, getPrimaryColor } from '../../../common/helper';
import { DashboardPageLayout } from '../../../layout/dashboard/DashboardPageLayout';
import { CommonResponse } from '../../../redux/model';
import { TypeEDMSendInBlue } from '../../../redux/reducer/edmSendInBlueReducer';
import { CommonNotificationSaveError, CommonNotificationUpdateSuccessfully } from '../../../shares/CommonNotification';
import { MetaHeader } from '../../../shares/MetaHeader';
import { TagPrimaryColor } from '../../../shares/TagPrimaryColor';
import { CommonDAL } from '../../../shares/dal/CommonDAL';
import { typeUpdateOrg } from '../../orgs/OrgsAction';
import { typeUpdateAcount } from '../ConsoleUsersAction';
import { PermissionGroups } from '../permisions/model/permissions';
import { FormUpdateUserInfo } from '../profile/FormUpdateUserInfo';
import { UserTokenDAL } from './dal/UserTokenDAL';
import { UserActivities } from './model/usertoken';

interface Props extends CommonTypeProps, FormComponentProps {
    updateAccount: typeUpdateAcount,
    updateOrgByAdminClient: typeUpdateOrg,
    changePwd: any,
    edmSendInBlue: TypeEDMSendInBlue,
    updateEDMSendInBlue: any,
    getEDMSendInBlue: any,
    getMe: any,
    searchPermissionGroup: any,
    permissionGroups: PermissionGroups
}

export class UserInfoForm extends React.Component<Props, any> {
    formRef: any
    formRefInfo: any
    formRefEDM: any

    state = {
        confirmDirty: false,
        visibleModalUpdateOrg: false,
        visibleInfo: false,
        imageUrl: null as any,
        fileUpload: null as RcFile | null,
        loadingUploadAv: false,
        activities: new UserActivities()
    };
    timer: any

    componentDidMount = () => {
        this.getActivity()
        this.props.searchPermissionGroup("")
        this.props.updateMetaTitle({ title: BIGDATA_CONSTANT.trackTitle.Home })
        this.timer = setTimeout(() => {
            this.props.updateMeta({
                name: BIGDATA_CONSTANT.trackTitle.Home,
                title: BIGDATA_CONSTANT.trackTitle.Home,
                app: "ACCOUNT",
                url: getCurrentURL(this.props.location)
            })
            clearTimeout(this.timer)
        }, 5000)
    }


    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    checkCurrentUserIsUser = (): boolean => {
        if (this.props.user) {
            if (this.props.user.orgCtx) {
                let roles = this.props.user.orgCtx.roles;
                let isSP = _.find(roles, (e) => {
                    return e == "USER"
                });

                if (isSP) {
                    return true
                }
                return false
            }
            return false
        }
        return false;
    }
    checkCurrentUserIsSuperAdmin = (): boolean => {
        if (this.props.user) {
            if (this.props.user.orgCtx) {
                let roles = this.props.user.orgCtx.roles;
                let isSP = _.find(roles, (e) => {
                    return e == "SUPER_ADMIN"
                });

                if (isSP) {
                    return true
                }
                return false
            }
            return false
        }
        return false;
    }

    renderBreadCrumb = () => {
        return (
            <Row type="flex" justify="space-between" align="middle" style={{ marginTop: 20 }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/">Home</Link>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>Profile</Breadcrumb.Item>
                </Breadcrumb>

            </Row>

        )
    }

    submitUpdateOrg = (e: any) => {
        const form = this.formRef.props.form;

        form.validateFields((err: any, values: any) => {
            if (err) {
                return;
            }

            this.props.setGlobalLoading(true)
            let isSAdmin = this.checkCurrentUserIsSuperAdmin();

            values.id = this.props.user.orgCtx ? this.props.user.orgCtx.id : null

            this.props.updateOrgByAdminClient(values, isSAdmin)
                .then(() => {
                    if (this.props.commonResponse.success) {
                        this.props.setGlobalLoading(false)
                        this.setState({ visibleModalUpdateOrg: false })
                        notification.success({
                            message: BIGDATA_CONSTANT.message.default.title.success,
                            description: `Organization ${this.props.user.orgCtx ? this.props.user.orgCtx.organization.name : ""} updated successfully`,
                            icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                        })
                        setTimeout(() => {
                            window.location.reload()
                        }, 200)
                    } else {
                        this.props.setGlobalLoading(false)
                        notification.error({
                            message: BIGDATA_CONSTANT.message.default.title.error,
                            description: this.props.commonResponse.message,
                        })
                        this.setState({ visibleModalUpdateOrg: false })
                    }


                })
                .catch((e: any) => {

                    this.props.setGlobalLoading(false)
                    notification.error({
                        message: BIGDATA_CONSTANT.message.default.title.error,
                        description: _.get(e.data, "message", "Something went wrong"),
                    })
                    this.setState({ visibleModalUpdateOrg: false })
                })
        })


    }





    submitUpdateInfo = (e: any) => {
        e.preventDefault();
        this.formRefInfo.props.form.validateFieldsAndScroll((err: any, values: any) => {
            if (!err) {
                let user: any = Object.assign(values, {
                    email: values.id,
                    status: 'active'
                })

                let promise: any = this.props.updateAccount(user, false, true)
                this.props.setGlobalLoading(true)
                promise
                    .then(() => {

                        if (this.props.commonResponse.success) {
                            notification.success({
                                message: BIGDATA_CONSTANT.message.default.title.success,
                                description: `User ${this.props.user.id} updated successfully`,
                                icon: <Icon type="check-circle" style={{ color: getPrimaryColor(this.props.uiSetting) }} />
                            })
                            this.setState({
                                visibleInfo: false
                            })


                            setTimeout(() => {
                                this.props.getMe()
                                    .then(() => {
                                        this.props.setGlobalLoading(false)
                                    })
                            }, 1000)


                        } else {
                            this.props.setGlobalLoading(false)
                            notification.error({
                                message: "Error",
                                description: this.props.commonResponse.message
                            })
                        }

                    })
                    .catch((error: any) => {
                        this.props.setGlobalLoading(false)
                        notification.error({
                            message: "Error",
                            description: _.get(error, "message", "Something was wrong.")
                        })
                    })

            }

        });
    }

    handleChangeAvatar = (info: RcFile) => {
        if (info.size / 1024 / 1024 > 2) {
            notification.warning({
                message: 'Warning',
                description: 'Please upload only image/png, image/jpeg, or image/jpg files less than 2MB.'
            })
            return false
        }
        this.setState({
            imageUrl: URL.createObjectURL(info),
            fileUpload: info
        })
        return false
    }

    handleUploadAvatar = async () => {
        const result = this.props.commonActionFromDALV2(UserTokenDAL.uploadAvatar, this.state.fileUpload)
        this.setState({
            loadingUploadAv: true
        })
        try {
            const res: CommonResponse = await result
            if (res.success) {
                setTimeout(() => {
                    this.props.getMe()
                }, 300);
                setTimeout(() => {
                    this.setState({
                        imageUrl: null,
                        fileUpload: null
                    })
                }, 400);
                CommonNotificationUpdateSuccessfully()
            } else {
                CommonNotificationSaveError(res.message)
            }
            this.setState({
                loadingUploadAv: false
            })
        } catch (error) {
            this.setState({
                loadingUploadAv: false
            })
            CommonNotificationSaveError(get(error, 'message'))
        }
    }

    getActivity = async () => {
        const result = this.props.commonActionFromDALV2(CommonDAL.buildURL,
            BIGDATA_CONSTANT.url.account + 'api/accounts/users/current/last-activity',
            {}, Methods.GET
        )
        try {
            const res: UserActivities = await result
            if (res.success) {
                this.setState({
                    activities: res
                })
            } else {
                this.setState({
                    activities: new UserActivities()
                })
            }
        } catch (error) {
            this.setState({
                activities: new UserActivities()
            })
        }
    }


    renderTheme() {
        let { name = "", avatar } = this.props.user
        let initials: any = name.match(/\b\w/g) || [];
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

        let { permission = { ctxGroupPermissions: undefined } as TypeUserPermission } = this.props.authen
        const { activities } = this.state
        return (
            <DashboardPageLayout {...this.props} selectedLeftNav="my-info" selectedSubMenu="my-account">
                <div className="org-page">
                    <MetaHeader title={this.props.ui.meta.title} />

                    <div className="contentLayout">
                        {this.renderBreadCrumb()}
                        <br />
                        <div className="containerInner">
                            <Row type="flex" gutter={[20, 20]}>
                                <Col span={3} lg={3} xs={0}>
                                    <Avatar src={this.state.imageUrl || avatar} style={{ background: getPrimaryColor(this.props.uiSetting) }} size={100} shape={"circle"}>
                                        {initials}
                                    </Avatar>
                                    {this.state.imageUrl ? (
                                        <div>
                                            <Button loading={this.state.loadingUploadAv} onClick={this.handleUploadAvatar} type="link" size="small">
                                                Save Change
                                            </Button>
                                            <Divider type="vertical" />
                                            <Button onClick={() => this.setState({ imageUrl: null })} type="link" size="small" icon="delete"></Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <Upload
                                                fileList={[]}
                                                accept={"image/png, image/jpeg, image/jpg"}
                                                beforeUpload={(file: RcFile, FileList: RcFile[]) => this.handleChangeAvatar(file)}
                                                multiple={false}>
                                                <Button type="link" size="small" icon="edit">
                                                    Change
                                                </Button>
                                            </Upload>
                                        </div>
                                    )}
                                </Col>
                                <Col xs={24} lg={16}>
                                    <span>
                                        <Row type="flex" justify={"space-between"}>
                                            <Typography.Title level={4}>
                                                Welcome, {name} [{this.props.user.id}]
                                            </Typography.Title>
                                            <Button
                                                onClick={() => {
                                                    this.setState({
                                                        visibleInfo: true,
                                                    });
                                                }}
                                                size="small"
                                                type="link"
                                                icon="edit">
                                                Edit profile
                                            </Button>
                                        </Row>

                                        <Typography.Text>
                                            @{get(this.props.user, "orgCtx.organization.name", null)} - Member since {moment(this.props.user.createdAt).format("MMMM DD, YYYY")}
                                            <div>
                                                <Icon type="phone" /> {this.props.user.phone}
                                                <span style={{ padding: "0 8px" }}>-</span>
                                                <Icon type="environment" /> {this.props.user.address || "--/--"}
                                            </div>
                                        </Typography.Text>

                                        <br />

                                        <Collapse activeKey={["login-activity"]}>
                                            <Collapse.Panel showArrow={false} header="Login Activity" key={"login-activity"}>
                                                <div>
                                                    Last changed password at {activities.lastChangePassword && activities.lastChangePassword.actionAt ? moment(activities.lastChangePassword.actionAt).format("LLLL") : "--/--"}
                                                </div>
                                                <div style={{ color: "green", margin: "5px 0px" }}>
                                                    Last login successfully at {activities.lastLoginSuccess && activities.lastLoginSuccess.actionAt ? moment(activities.lastLoginSuccess.actionAt).format("LLLL") : "--/--"}
                                                </div>
                                                <div style={{ color: "red" }}>
                                                    Last login failed at {activities.lastLoginFailed && activities.lastLoginFailed.actionAt ? moment(activities.lastLoginFailed.actionAt).format("LLLL") : "--/--"}
                                                </div>

                                                <div style={{ marginTop: 10 }}>
                                                    <Link
                                                        to={{
                                                            pathname: "activity/login-history",
                                                        }}>
                                                        View All
                                                    </Link>
                                                </div>
                                            </Collapse.Panel>
                                        </Collapse>

                                        <br />

                                        <Collapse activeKey={["roles"]}>
                                            <Collapse.Panel showArrow={false} header="Roles" key={"roles"}>
                                                {get(this.props.user, "orgCtx.roles", []).map((role: string) => {
                                                    return (
                                                        <TagPrimaryColor style={{ marginBottom: 8 }} uiSetting={this.props.uiSetting}>
                                                            {role}
                                                        </TagPrimaryColor>
                                                    );
                                                })}
                                            </Collapse.Panel>
                                        </Collapse>
                                        <br />
                                        <Collapse activeKey={["permission"]}>
                                            <Collapse.Panel showArrow={false} header="Permissions" key={"permission"}>
                                                {permission &&
                                                    isArray(permission.groupIds) &&
                                                    permission.groupIds.map((groupId: string) => {
                                                        const permissionName = get(this.props.permissionGroups.results, groupId, groupId);
                                                        return (
                                                            <TagPrimaryColor style={{ marginBottom: 8 }} uiSetting={this.props.uiSetting}>
                                                                {permissionName}
                                                            </TagPrimaryColor>
                                                        );
                                                    })}
                                                {!permission || (permission && isArray(permission.groupIds) && permission.groupIds.length === 0) ? "No permissions" : null}
                                            </Collapse.Panel>
                                        </Collapse>
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </DashboardPageLayout>
        );
    }


    renderDraweInfo = () => {
        return (
            <Drawer
                title="Update personal info"
                visible={this.state.visibleInfo}
                onClose={() => this.setState({
                    visibleInfo: false
                })}
                width={"400px"}
            >
                <FormUpdateUserInfo
                    loading={this.props.ui.isLoading}
                    locale={this.props.locale}
                    orgCtx={this.props.user.orgCtx}
                    wrappedComponentRef={(propsRef: any) => this.formRefInfo = propsRef}
                    submit={this.submitUpdateInfo}
                    user={this.props.user}
                />
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e8e8e8',
                        padding: '10px 16px',
                        textAlign: 'right',
                        left: 0,
                        background: '#fff',
                        borderRadius: '0 0 4px 4px',
                    }}
                >
                    <Button
                        style={{
                            marginRight: 8,
                        }}
                        onClick={() => this.setState({
                            visibleInfo: false
                        })}
                    >
                        Cancel
                    </Button>
                    <Button onClick={this.submitUpdateInfo} type="primary">
                        Save and Close
                    </Button>
                </div>
            </Drawer>
        )
    }

    render() {
        return (
            <React.Fragment>
                {this.renderTheme()}
                {this.renderDraweInfo()}
            </React.Fragment>

        );
    }
}

export const UserInfoComponent = Form.create<Props>({ name: 'profile_form' })(UserInfoForm)